import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonLabel,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';
import API from 'services';
import { useUserContext } from 'context/UserContext';

interface Empresa {
  id: number;
  nome: string;
}

interface Props {
  title?: string;
  onClose?: () => void;
  setState: React.Dispatch<React.SetStateAction<Empresa | undefined>>
  state: Empresa | undefined;
}

function ModalEmpresa({ setState, state, title, onClose }: Props) {
  const { user } = useUserContext()
  const [filtroEmpresa, setFiltroEmpresa] = useState("");
  const [listaDeEmpresas, setListaDeEmpresas] = useState<Empresa[]>([]);

  useEffect(() => {
    API.post("/usuarios/funcionarios/funcionarios_empresasapi.php", { id: user?.id })
      .then((resp) => {
        console.log(resp)
        setListaDeEmpresas(resp.data)
      })
      .catch((err) => {
        alert(err)
      })
  }, [filtroEmpresa])

  const searchbarInput = (ev: any) => {
    setFiltroEmpresa(ev.target.value);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onClose} color="danger" fill='solid'>Fechar</IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose} color="success" fill='solid'>Concluir</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar onIonInput={searchbarInput}></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
        {listaDeEmpresas.length > 0 ?
          (
            <IonRadioGroup value={state} onIonChange={(ev) => { setState(ev.detail.value) }}>
              {listaDeEmpresas.map((empresa) => (
                <IonItem key={empresa.id}>
                  <IonLabel>{empresa.nome}</IonLabel>
                  <IonRadio value={empresa} />
                </IonItem>
              ))}
            </IonRadioGroup>
          ) :
          (
            <IonItem>Nem uma empresa cadastrada com esse nome...</IonItem>
          )
        }
      </IonContent>
    </>
  );
}
export default ModalEmpresa;
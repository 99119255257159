import { IonButton, IonCard, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonImg, IonItem, IonPage, IonRouterLink, IonRow, IonText, useIonAlert } from "@ionic/react";
import { useState } from "react";
import { useUserContext } from "context/UserContext";
import API from "services";
import brasaoES from "./brasaoES.png";
import styles from "./Login.module.scss";
import InputItem from "components/Input";
import { useHistory } from "react-router";

const Login = () => {
  const { handleLogin } = useUserContext();
  const [inputEmail, setInputEmail] = useState<string | number | null | undefined>("");
  const [inputSenha, setInputSenha] = useState<string | number | null | undefined>("");
  const [presentAlert] = useIonAlert();
  const history = useHistory();

  const solicitaLogin = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const usuario = {
      email: inputEmail,
      senha: inputSenha,
    };

    API.post("/login/loginapi.php", usuario)
      .then((res) => {
        console.log(res)
        if (res.data.error) {
          presentAlert({
            header: "Atenção",
            message: `${res.data.message}`,
            buttons: ["OK"],
          });
        } else {
          // setUser(res.data.user);
          // localStorage.setItem("user", JSON.stringify(res.data.user));
          handleLogin(res.data.user);
          history.push("/home")
        }
      })
      .catch((erro) => {
        alert(erro.message);
      });
  };

  return (
    <IonPage className={styles.page}>
      <IonCard className={styles.card}>
        <IonCardHeader>
          <IonCardTitle>Alimentos e Familia</IonCardTitle>
        </IonCardHeader>

        <IonImg className={styles.img} src={brasaoES} alt="logo" />

        <form onSubmit={(ev) => solicitaLogin(ev)} style={{ width: "100%" }}>
          <InputItem
            required
            type="email"
            state={inputEmail}
            setState={setInputEmail}
            position="stacked"
            placeholder="E-mail"
            label="E-mail"
          />
          <InputItem
            required
            type="password"
            state={inputSenha}
            setState={setInputSenha}
            position="stacked"
            placeholder="Senha"
            label="Senha"
          />

          <IonItem>
            <IonButton
              type="submit"
              style={{ width: "100%" }}
              size="default"
            >
              Login
            </IonButton>
          </IonItem>
        </form>

        <IonGrid>
          <IonCol>
            <IonRow class="ion-justify-content-center">
              <IonRouterLink routerLink="/recuperarSenha">Esqueci minha senha </IonRouterLink>
            </IonRow>
            <IonRow class="ion-justify-content-center">
              <IonText>
                Não tem cadastro? {"   "}
                <IonRouterLink routerLink="/fazerCadastro">Cadastre-se aqui</IonRouterLink>
              </IonText>
            </IonRow>
          </IonCol>
        </IonGrid>

      </IonCard>
    </IonPage>
  );
};

export default Login;

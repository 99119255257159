/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import { IonApp, IonProgressBar, IonRouterOutlet, IonSplitPane, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { useUserContext } from "context/UserContext";
import { lazy, useContext, Suspense, useEffect } from "react";
import Login from "pages/Comum/Login";
import CadastroUsuario from "pages/Comum/CadastroUsuario";
import CadastrarPessoa from "pages/FuncionarioTJ/CadastrarPessoa";
import ProcurarPessoa from "pages/FuncionarioTJ/ProcurarPessoa";
import Configuracoes from "pages/Comum/Configuracoes";
import ConfigAvatar from "pages/Comum/Configuracoes/ConfigAvatar";
import ConfigSenha from "pages/Comum/Configuracoes/ConfigSenha";
import EditarPessoa from "pages/FuncionarioTJ/EditarPessoa";
import CadastrarEmpresa from "pages/Empresa/CadastrarEmpresa";
import Cabecalho from "components/Cabecalho";
import Home from "pages/Comum/Home";
import Menu from "components/Menu";
import PaginaPJ from "pages/Admin/PaginaPJ";
import PaginaOrgao from "pages/Admin/PaginaOrgao";
import PaginaEmpresas from "pages/Admin/PaginaEmpresas";
import PaginaUsuarios from "pages/Admin/PaginaUsuarios";
import Processos from "pages/FuncionarioTJ/Processos";
import EditarAtividade from "pages/Empresa/EditarAtividade";
import TodasAtividades from "pages/Empresa/TodasAtividades";
import CadastrarAtividade from "pages/Empresa/CadastrarAtividade";
import CadastroProcesso from "pages/FuncionarioTJ/CadastroProcesso";
import ProcessoDetalhado from "pages/FuncionarioTJ/ProcessoDetalhado";

setupIonicReact();

function Routes() {
  const { loginValido } = useUserContext();

  if (loginValido) {
    console.log("[IF] - loginValido: TRUE")
    return (
      <IonApp>
        <Suspense fallback={<IonProgressBar type="indeterminate" />}>
          <IonReactRouter>
          <Route path="/login">
              <Redirect to="/home" />
              {/* <Route path="/home" exact={true} component={Home} /> */}
            </Route>

            <IonSplitPane contentId="main">
              <Menu />

              <IonRouterOutlet id="main">
                <Route path="/home" component={Home} />

                {/* Rotas de FuncionarioTJ */}
                <Route path="/procurar_pessoas" component={ProcurarPessoa} />
                <Route path="/cadastrar_pessoa" component={CadastrarPessoa} />
                <Route path="/editar_pessoa/:id" component={EditarPessoa} />
                <Route path="/processo/cadastro" exact={true} component={CadastroProcesso} />
                <Switch>
                  <Route path="/processos" exact={true} component={Processos} />
                  <Route path="/processo/:id" component={ProcessoDetalhado} />
                </Switch>

                {/* Rotas Comuns */}
                <Route path="/config" exact={true} component={Configuracoes} />
                <Route path="/config/avatar" exact={true} component={ConfigAvatar} />
                <Route path="/config/senha" exact={true} component={ConfigSenha} />

                {/* Rotas de Empresa */}
                <Route path="/todas_atividades" exact={true} component={TodasAtividades} />
                <Route path="/cadastrar_atividades" exact={true} component={CadastrarAtividade} />
                <Route path="/cadastrar_empresa" exact={true} component={CadastrarEmpresa} />

              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
        </Suspense>
      </IonApp>
    )
  } else {
    console.log("[ELSE] - loginValido: FALSE")
    return (
      <IonApp>
        <Suspense fallback={<IonProgressBar type="indeterminate" />}>
          <IonReactRouter>
            <Route path="/">
              <Redirect to="/login" />
              <Route path="/login" exact={true} component={Login} />
              <Route path="/fazerCadastro" exact={true} component={CadastroUsuario} />
            </Route>
          </IonReactRouter>
        </Suspense>
      </IonApp>
    )
  }
}

export default Routes;

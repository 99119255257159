import { useEffect, useState } from "react";
import { useParams } from "react-router";
import processos from "data/processo_mock.json";
import { IonHeader, IonToolbar, IonTitle, IonCard, IonCardHeader, IonInput, IonItem, IonLabel, IonTextarea, IonButton, IonCardTitle, IonCardContent, IonSegment, IonSegmentButton, IonContent, IonProgressBar } from "@ionic/react";
import styles from "./PaginaProcessoDetalhado.module.scss";
import { IonSegmentCustomEvent } from "@ionic/core";
import formataDataDMAparaAMD from "common/function/formataDataDMAparaAMD";
import imgError from "./imgError.png";

interface IParametro {
    id: string;
}

type IProcesso = {
    id: number;
    processo: string;
    empregador: string;
    empregado: string;
    magistrado: string;
    datacadastro: string;
    situacao: number;
    observacao: string;
    orgao: string;
};

interface SegmentChangeEventDetail {
    value?: string;
}

function ProcessoDetalhado() {
    const parametro = useParams<IParametro>();
    const [processo, setProcesso] = useState<IProcesso>();
    const processoFind = processos.find((proc) => proc.id === +parametro.id);
    const [habilitaEdicao, setHabilitaEdicao] = useState(false);
    const [visualizarEditar, setVisualizarEditar] = useState("visualizar");

    // Altera o formado da string de data de dd/mm/yyyy para yyyy-mm-dd
    useEffect(() => {
        if (processoFind === undefined) return;

        processoFind.datacadastro = formataDataDMAparaAMD(processoFind.datacadastro);
        setProcesso(processoFind);
    }, []);

    const HandleChange = (event: IonSegmentCustomEvent<SegmentChangeEventDetail>) => {
        setVisualizarEditar(event.target.value!);
        setHabilitaEdicao(event.target.value === "visualizar" ? false : true);
    };

    return (
        <IonContent>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Detalhes do Processo</IonTitle>
                </IonToolbar>
            </IonHeader>

            <div className={styles.container}>
                {processo ? (
                    <IonCard className={styles.card}>
                        <IonSegment value={visualizarEditar} onIonChange={(e) => HandleChange(e)}>
                            <IonSegmentButton value="visualizar">
                                <IonLabel>Visualizar</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value="editar">
                                <IonLabel>Editar</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>

                        <IonCardHeader>
                            <IonItem>
                                <IonCardTitle>Detalhes do Processo</IonCardTitle>
                            </IonItem>
                        </IonCardHeader>

                        <IonCardContent>
                            <IonItem>
                                <IonLabel position="floating">Numero do Processo</IonLabel>
                                <IonInput disabled={!habilitaEdicao} value={processo.processo} placeholder="Numero do Processo"></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Empregador</IonLabel>
                                <IonInput disabled={!habilitaEdicao} value={processo.empregador} placeholder="Empregador"></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Empregado</IonLabel>
                                <IonInput disabled={!habilitaEdicao} value={processo.empregado} placeholder="Empregado"></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Magistrado</IonLabel>
                                <IonInput disabled={!habilitaEdicao} value={processo.magistrado} placeholder="Magistrado"></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Data Cadastro</IonLabel>
                                <IonInput disabled={!habilitaEdicao} value={processo.datacadastro} type="date" placeholder="Data Cadastro"></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Situação</IonLabel>
                                <IonInput disabled={!habilitaEdicao} value={processo.situacao} placeholder="Situação"></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Orgão</IonLabel>
                                <IonInput disabled={!habilitaEdicao} value={processo.orgao} placeholder="Orgão"></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Observação</IonLabel>
                                <IonTextarea disabled={!habilitaEdicao} value={processo.observacao} placeholder="Observação" autoGrow={true}></IonTextarea>
                            </IonItem>

                            <div className={styles.botoes}>
                                <IonButton color="danger" fill="outline">
                                    Voltar
                                </IonButton>
                                <IonButton disabled={!habilitaEdicao} color="success" fill="solid">
                                    Salvar
                                </IonButton>
                            </div>
                        </IonCardContent>
                    </IonCard>
                ) : (
                    <div className={styles.error}>
                        <p>Algo de errado não esta certo, tente recarregar a pagina...</p>
                        <img src={imgError} alt="deu Ruim" />
                        <IonProgressBar type="indeterminate" />
                    </div>
                )}
            </div>
        </IonContent>
    );
}

export default ProcessoDetalhado;

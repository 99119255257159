import { IonAvatar, IonButton, IonItem as IonCard, IonCardContent, IonContent, IonItem } from '@ionic/react'
import { useState } from 'react'
import styles from "./ConfigAvatar.module.scss";
import { BigHead } from '@bigheads/core';
import Cabecalho from 'components/Cabecalho';
import ItemPersonalizavel from './ItemPersonalizavel';

interface IAvatar {
   accessory: "none" | "roundGlasses" | "tinyGlasses" | "shades" | undefined,
   body: "chest" | "breasts" | undefined,
   clothing: "tankTop" | "naked" | "shirt" | "dressShirt" | "vneck" | "dress" | undefined,
   clothingColor: "blue" | "black" | "white" | "green" | "red" | undefined,
   eyebrows: "angry" | "raised" | "leftLowered" | "serious" | "concerned" | undefined,
   eyes: "wink" | "normal" | "leftTwitch" | "happy" | "content" | "squint" | "simple" | "dizzy" | "heart" | undefined,
   facialHair: "none" | "mediumBeard" | "none2" | "none3" | "stubble" | undefined,
   graphic: "none" | "react" | "redwood" | "gatsby" | "vue" | "graphQL" | undefined,
   hair: "none" | "short" | "long" | "bun" | "pixie" | "balding" | "buzz" | "afro" | "bob" | undefined,
   hairColor: "blue" | "black" | "white" | "blonde" | "orange" | "brown" | "pink" | undefined,
   hat: "none" | "none2" | "none3" | "none4" | "none5" | "beanie" | "turban" | undefined,
   hatColor: "blue" | "black" | "white" | "green" | "red" | undefined,
   lashes: boolean | undefined,
   lipColor: "green" | "red" | "pink" | "purple" | "turqoise" | undefined,
   mouth: "serious" | "open" | "grin" | "sad" | "openSmile" | "lips" | "tongue" | undefined,
   skinTone: "black" | "red" | "brown" | "light" | "dark" | "yellow" | undefined,
}

export default function ConfigAvatar() {
   const avatarLocalStorage = localStorage.getItem("avatarConfig");
   let avatarConfig: IAvatar = avatarLocalStorage ? JSON.parse(avatarLocalStorage) : "";

   const [accessory, setAccessory] = useState(avatarConfig.accessory ? avatarConfig.accessory : "tinyGlasses");
   const accessoryOptions = ["none", "roundGlasses", "tinyGlasses", "shades"]

   const [body, setBody] = useState(avatarConfig.body ? avatarConfig.body : "breasts");
   const bodyOptions = ["chest", "breasts"]

   const [clothing, setClothing] = useState(avatarConfig.clothing ? avatarConfig.clothing : "shirt");
   const clothingOptions = ["tankTop", "naked", "shirt", "dressShirt", "vneck", "dress"]

   const [clothingColor, setClothingColor] = useState(avatarConfig.clothingColor ? avatarConfig.clothingColor : "white");
   const clothingColorOptions = ["blue", "black", "white", "green", "red"]

   const [eyebrows, setEyebrows] = useState(avatarConfig.eyebrows ? avatarConfig.eyebrows : "leftLowered");
   const eyebrowsOptions = ["angry", "raised", "leftLowered", "serious", "concerned"]

   const [eyes, setEyes] = useState(avatarConfig.eyes ? avatarConfig.eyes : "heart");
   const eyesOptions = ["wink", "normal", "leftTwitch", "happy", "content", "squint", "simple", "dizzy", "heart"]

   const [facialHair, setFacialHair] = useState(avatarConfig.facialHair ? avatarConfig.facialHair : "none");
   const facialHairOptions = ["none", "mediumBeard", "stubble"]

   const [graphic, setGraphic] = useState(avatarConfig.graphic ? avatarConfig.graphic : "react");
   const graphicOptions = ["none", "react", "redwood", "gatsby", "vue", "graphQL"]

   const [hair, setHair] = useState(avatarConfig.hair ? avatarConfig.hair : "bob");
   const hairOptions = ["none", "short", "long", "bun", "pixie", "balding", "buzz", "afro", "bob"]

   const [hairColor, setHairColor] = useState(avatarConfig.hairColor ? avatarConfig.hairColor : "pink");
   const hairColorOptions = ["blue", "black", "white", "blonde", "orange", "brown", "pink"]

   const [hat, setHat] = useState(avatarConfig.hat ? avatarConfig.hat : "beanie");
   const hatOptions = ["none", "beanie", "turban"]

   const [hatColor, setHatColor] = useState(avatarConfig.hatColor ? avatarConfig.hatColor : "green");
   const hatColorOptions = ["blue", "black", "white", "green", "red"];

   const [lashes, setLashes] = useState(avatarConfig.lashes ? avatarConfig.lashes : true);
   const lashesOptions = [true, false];

   const [lipColor, setLipColor] = useState(avatarConfig.lipColor ? avatarConfig.lipColor : "purple");
   const lipColorOptions = ["green", "red", "pink", "purple", "turqoise"];

   const [mouth, setMouth] = useState(avatarConfig.mouth ? avatarConfig.mouth : "openSmile");
   const mouthOptions = ["serious", "open", "grin", "sad", "openSmile", "lips", "tongue"]

   const [skinTone, setSkinTone] = useState(avatarConfig.skinTone ? avatarConfig.skinTone : "light");
   const skinToneOptions = ["black", "red", "brown", "light", "dark", "yellow"];

   return (
      <IonContent>
         <Cabecalho texto='Personalizar meu Avatar' />
         <IonCard >
            <div className={styles.avatarCard}>
               <IonAvatar className={styles.avatarImage}>
                  <BigHead
                     accessory={accessory}
                     body={body}
                     clothing={clothing}
                     clothingColor={clothingColor}
                     eyebrows={eyebrows}
                     eyes={eyes}
                     facialHair={facialHair}
                     graphic={graphic}
                     hair={hair}
                     hairColor={hairColor}
                     hat={hat}
                     hatColor={hatColor}
                     lashes={lashes}
                     lipColor={lipColor}
                     mouth={mouth}
                     skinTone={skinTone}
                  />
               </IonAvatar>

               <IonCardContent className={styles.avatarContent}>

                  <ItemPersonalizavel label='Oculos' options={accessoryOptions} value={accessory} setValue={setAccessory} />

                  <ItemPersonalizavel label='Tipo de Corpo' options={bodyOptions} value={body} setValue={setBody} />

                  <ItemPersonalizavel label='Estilo da Roupa' options={clothingOptions} value={clothing} setValue={setClothing} />

                  <ItemPersonalizavel label='Cor da Roupa' options={clothingColorOptions} value={clothingColor} setValue={setClothingColor} />

                  <ItemPersonalizavel label='Estilo das Sobrancelhas' options={eyebrowsOptions} value={eyebrows} setValue={setEyebrows} />

                  <ItemPersonalizavel label='Estilo dos Olhos' options={eyesOptions} value={eyes} setValue={setEyes} />

                  <ItemPersonalizavel label='Estilo da Barba' options={facialHairOptions} value={facialHair} setValue={setFacialHair} />

                  <ItemPersonalizavel label='Estampa da Camisa' options={graphicOptions} value={graphic} setValue={setGraphic} />

                  <ItemPersonalizavel label='Estilo do Cabelo' options={hairOptions} value={hair} setValue={setHair} />

                  <ItemPersonalizavel label='Cor do Cabelo' options={hairColorOptions} value={hairColor} setValue={setHairColor} />

                  <ItemPersonalizavel label='Chapeu' options={hatOptions} value={hat} setValue={setHat} />

                  <ItemPersonalizavel label='Cor do Chapeu' options={hatColorOptions} value={hatColor} setValue={setHatColor} />

                  <ItemPersonalizavel label='Cor dos Labios' options={lipColorOptions} value={lipColor} setValue={setLipColor} />

                  <ItemPersonalizavel label='Boca' options={mouthOptions} value={mouth} setValue={setMouth} />

                  <ItemPersonalizavel label='Cor de Pele' options={skinToneOptions} value={skinTone} setValue={setSkinTone} />

                  <IonItem>
                     <IonButton size='default' color="danger" fill='outline'>Limpar</IonButton>
                     <IonButton slot='end' size='default' color="success" fill='outline' onClick={() => {
                        const avatarConfig = {
                           accessory,
                           body,
                           clothing,
                           clothingColor,
                           eyebrows,
                           eyes,
                           facialHair,
                           graphic,
                           hair,
                           hairColor,
                           hat,
                           hatColor,
                           lashes,
                           lipColor,
                           mouth,
                           skinTone
                        }
                        localStorage.setItem("avatarConfig", JSON.stringify(avatarConfig))
                     }}>Confirmar</IonButton>
                  </IonItem>
               </IonCardContent>
            </div>
         </IonCard>
      </IonContent>
   )
}

import { IonContent, IonItem, IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import styles from "./ProcurarPessoa.module.scss";
import API from "services";
import BarraPesquisa from "components/BarraPesquisa";
import Cabecalho from "components/Cabecalho";
import ButtonRouter from "components/ButonRouter";
import { pencilOutline } from "ionicons/icons";

interface IPessoa {
  cpf: "",
  id: number,
  nome: ""
}

export default function ProcurarPessoa() {
  const [filtro, setfiltro] = useState("");
  const [pessoas, setPessoas] = useState<IPessoa[] | null>();

  useEffect(() => {
    if (filtro.length >= 3) {
      API.post("/usuarios/empregadosapi.php", { empregado: filtro })
        .then((res) => {
          setPessoas(res.data.empregados.slice(0, 10));
          console.log(res)
        })
        .catch((err) => {
          alert(err.message);
        });
    }
    setPessoas(null);
  }, [filtro, setfiltro]);

  return (
    <IonContent>
      <Cabecalho texto="Procurar Pessoa">
        <BarraPesquisa placeholder="Nome Pessoa" busca={filtro} setBusca={setfiltro} />
      </Cabecalho>

      <section className={styles.constainerListaPessoas}>
        {pessoas?.length ? (
          pessoas.map((pessoa) => {
            return (
              <IonCard className={styles.Card} key={pessoa.id}>
                <IonCardHeader>
                  <IonCardTitle>{pessoa.nome}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonText>CPF: {pessoa.cpf}</IonText>
                </IonCardContent>
                <IonItem>
                  <ButtonRouter
                    slotButton="end"
                    slotIcon="end"
                    routerLink={`processo/:${pessoa.id}`}
                    icon={pencilOutline}
                    text="editar"
                    size="small"
                    fill="default"
                    colorButton="dark"
                    colorIcon="dark"
                  />
                </IonItem>
              </IonCard>
            );
          })
        ) : (
          <div className={styles.containerListaVazia}>
            <p>Nem uma pessoa encontrada...</p>
          </div>
        )}
      </section>
    </IonContent>
  );
}

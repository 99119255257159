import { IonItem, IonLabel, IonInput } from '@ionic/react'
import React from 'react'

interface Props {
  label?: string;
  placeholder?: string;
  type?: "password" | "number" | "email";
  required?: boolean;
  minlength?: number | undefined;
  position?: "fixed" | "floating" | "stacked" | undefined;
  state: string | number | null | undefined;
  setState: React.Dispatch<React.SetStateAction<string | number | null | undefined>>
}

export default function InputItem({ label, placeholder, type = undefined, required = false, minlength = undefined, position = undefined, setState, state }: Props) {
  return (
    <IonItem>
      <IonLabel position={position}>{label}</IonLabel>
      <IonInput
        placeholder={placeholder}
        type={type}
        required={required}
        minlength={minlength}
        onIonChange={(ev) => {
          setState(ev.target.value)
        }}
        value={state} 
      />
    </IonItem>
  )
}

import { IonAlert } from "@ionic/react";
import React, { ReactElement } from "react";

interface IProps {
    textCancel?: string;
    textConfirm?: string;
    header?: string;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    
}

export default function Alerta ({textCancel = "Cancelar", textConfirm = "Confirmar", header, setIsOpen, isOpen}: IProps) {

    return (
        <React.Fragment>
            <IonAlert
                header={header}
                buttons={[
                    {
                        text: `${textCancel}`,
                        role: "cancel",
                        cssClass: "alert-button-cancel",
                        handler: () => {
                            setIsOpen(!isOpen);
                            console.log("cancel")
                        },
                    },
                    {
                        text: `${textConfirm}`,
                        role: "confirm",
                        cssClass: "alert-button-confirm",
                        handler: () => {
                            // window.location.href = "/login";
                            
                        },
                    },
                ]}
                isOpen={isOpen}
            ></IonAlert>
        </React.Fragment>
    );
}

import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonLabel,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';
import API from 'services';

interface IPessoa {
  id: number;
  nome: string;
  cpf: string;
}

interface Props {
  title?: string;
  onClose?: () => void;
  setState: React.Dispatch<React.SetStateAction<IPessoa | undefined>>
  state: IPessoa | undefined;
}

function ModalPessoa({ setState, state, title, onClose }: Props) {
  const [filtroPessoa, setFiltroPessoa] = useState("");
  const [listaDePessoas, setListaDePessoas] = useState<IPessoa[]>([]);

  useEffect(() => {
    if (filtroPessoa.length >= 3) {
      API.post("/usuarios/empregadosapi.php", { empregado: filtroPessoa })
        .then((res) => {
          setListaDePessoas(res.data.empregados.slice(0, 50))
        })
        .catch((err) => { 
          alert(err)
        })
    }

    if (filtroPessoa.length === 0) {
      setListaDePessoas(listaDePessoas.slice(0, 0))
    }
  }, [filtroPessoa])

  const searchbarInput = (ev: any) => {
    setFiltroPessoa(ev.target.value);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onClose} color="danger" fill='solid'>Fechar</IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose} color="success" fill='solid'>Concluir</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar onIonInput={searchbarInput}></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
        <IonRadioGroup value={state} onIonChange={(ev) => { setState(ev.detail.value) }}>
          {listaDePessoas.map((pessoa) => (
            <IonItem key={pessoa.id}>
              <IonLabel>{`${pessoa.nome} - ${pessoa.cpf}`}</IonLabel>
              <IonRadio value={pessoa} />
            </IonItem>
          ))}
        </IonRadioGroup>
      </IonContent>
    </>
  );
}
export default ModalPessoa;
import { IonAlert, IonButton, IonCard, IonCardContent, IonContent, IonItem, IonPage, IonPopover, IonTitle, useIonAlert } from '@ionic/react'
import Cabecalho from 'components/Cabecalho';
import InputItem from 'components/Input'
import { useUserContext } from 'context/UserContext';
import { useState } from 'react'
import API from 'services';

export default function ConfigSenha() {
  const [error, setError] = useState(false);
  const [errorMesage, setErrorMesage] = useState("");
  const [senhaAtual, setSenhaAtual] = useState<string | number | null | undefined>("");
  const [novaSenha, setNovaSenha] = useState<string | number | null | undefined>("");
  const [repetirSenha, setRepetirSenha] = useState<string | number | null | undefined>("");
  const { user } = useUserContext();

  const submit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    console.log(`email: ${user?.email}, senha: ${senhaAtual}, nova_senha: ${novaSenha}, repetir_senha: ${repetirSenha}`)

    if (novaSenha === repetirSenha) {
      API.post("/configuracoes/mudarSenhaapi.php", { email: user?.email, senha: senhaAtual, nova_senha: novaSenha, repetir_senha: repetirSenha })
        .then((resp) => {
          console.log(resp)
          if(resp.data.error) {
            setError(true);
            setErrorMesage(resp.data.message)
          }
        })
        .catch((err) => {
          alert(err)
        })
    } else {
      setError(true);
    }
  }

  return (
    <IonPage>
      <Cabecalho texto='Alterar Senha' />
      <IonContent>
        <IonCard>
          <IonCardContent>
            <form onSubmit={(ev) => submit(ev)}>
              <InputItem type='password' label='Senha Atual' setState={setSenhaAtual} state={senhaAtual} />
              <InputItem type='password' label='Nova Senha' setState={setNovaSenha} state={novaSenha} />
              <InputItem type='password' label='Confirmar Senha' setState={setRepetirSenha} state={repetirSenha} />

              <IonItem>
                <IonButton type='reset' size='default' color="danger" fill='solid'>Limpar</IonButton>
                <IonButton type='submit' slot='end' size='default' color="success" fill='solid'>Confirmar</IonButton>
              </IonItem>
            </form>
          </IonCardContent>
        </IonCard>
      </IonContent>
      <IonAlert
        isOpen={error}
        onDidDismiss={() => setError(false)}
        header="Atenção"
        subHeader={errorMesage}
        buttons={['OK']}
      />
    </IonPage>
  )
}

import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonInput, IonItem, IonLabel } from '@ionic/react'
import Cabecalho from 'components/Cabecalho'
import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import API from 'services';
import { useForm } from "hooks/useForm";
import InputItem from "components/Input"
import FormPessoa from "components/FormPessoa";
import styles from "./EditarPessoa.module.scss"
import { stringify } from 'querystring';

interface Paramas {
  id?: string;
}

export default function EditarPessoa() {
  const params: Paramas = useParams();
  const [pessoa, setPessoa] = useState();
  

  function updatePessoaCallback() {
    console.log("[updatePessoaCallback]")
  }

  useEffect(() => {
    API.post("/pessoaById/pessoaFisiacaById.php", {id: params.id })
      .then((resp) => {
        console.log(resp.data)
        setPessoa(resp.data);
      })
      .catch((error) => {
        console.log(error)
      })
  }, [params])

  return (
    <IonContent>
      <Cabecalho texto='Editar Pessoa' />
      <section className={styles.container}>
        <IonCard className={styles.card}>

          <IonCardHeader>
            <IonCardTitle>Dados Pessoais</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>

            <FormPessoa
              type="atualizacao"
              idPessoa={params.id}
              pessoa={pessoa}              
              tipoPessoa='F'
            />

          </IonCardContent>
        </IonCard>
      </section>
    </IonContent>
  )
}

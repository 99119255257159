import { IonAvatar, IonButton, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRow } from "@ionic/react";
import { Link, useLocation } from "react-router-dom";
import { listOutline, buildOutline, addCircleOutline, folderOpenOutline, businessOutline, peopleOutline, apertureOutline, searchOutline, personAddOutline } from "ionicons/icons";
import "./Menu.css";
import LogOut from "./LogOut";
import { UserContext, useUserContext } from "context/UserContext";
import { useEffect, useState } from "react";
import { PerfilUsuario } from "enums/UsuarioPrivilegio";
import { settingsOutline } from "ionicons/icons";
import Avatar from "components/Avatar";
import avatar from "assets/avatar.png"
import ButtonRouter from "components/ButonRouter";
import { Usuario } from "types/Usuario";

interface IItemMenu {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const menuADM: IItemMenu[] = [
  {
    title: "Processos",
    url: "/processos",
    iosIcon: folderOpenOutline,
    mdIcon: folderOpenOutline,
  },
  {
    title: "Empresas",
    url: "/empresas",
    iosIcon: businessOutline,
    mdIcon: businessOutline,
  },
  {
    title: "Usuários",
    url: "/usuarios",
    iosIcon: peopleOutline,
    mdIcon: peopleOutline,
  },
  {
    title: "Orgão",
    url: "/orgao",
    iosIcon: apertureOutline,
    mdIcon: apertureOutline,
  }
];

const menuPJ: IItemMenu[] = [
  {
    title: "Todas Atividades",
    url: "/todas_atividades",
    iosIcon: listOutline,
    mdIcon: listOutline,
  },
  {
    title: "Cadastrar Atividades",
    url: "/cadastrar_atividades",
    iosIcon: buildOutline,
    mdIcon: buildOutline,
  },
  {
    title: "Adicionar Empresa",
    url: "/cadastrar_empresa",
    iosIcon: addCircleOutline,
    mdIcon: addCircleOutline,
  },
];

const menuMembroTJ: IItemMenu[] = [
  {
    title: "Processos",
    url: "/processos",
    iosIcon: folderOpenOutline,
    mdIcon: folderOpenOutline,
  },
  {
    title: "Procurar Pessoa",
    url: "/procurar_pessoas",
    iosIcon: searchOutline,
    mdIcon: searchOutline,
  },
  {
    title: "Cadastrar Pessoa",
    url: "/cadastrar_pessoa",
    iosIcon: personAddOutline,
    mdIcon: personAddOutline,
  },
];

const menuPF: IItemMenu[] = [
  {
    title: "Procurar Pessoa",
    url: "/procurar_pessoas",
    iosIcon: searchOutline,
    mdIcon: searchOutline,
  },
];

const Menu = () => {
  const location = useLocation();
  const [tipoMenu, setTipoMenu] = useState<IItemMenu[]>();
  const { user } = useUserContext();

  useEffect(() => {
    switch (user?.perfil) {
      case PerfilUsuario.ADM:
        setTipoMenu(menuADM);
        break;
      case PerfilUsuario.PJ:
        setTipoMenu(menuPJ);
        break;
      case PerfilUsuario.MembroTJ:
        setTipoMenu(menuMembroTJ);
        break;
      case PerfilUsuario.PF:
        setTipoMenu(menuPF);
        break;
      default:
    }
  }, []);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>
            <IonAvatar className="avatar">
              {/* <Avatar /> */}
              <img src={avatar} />
            </IonAvatar>
            <IonNote>{user?.email}</IonNote>
          </IonListHeader>

          {tipoMenu?.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={location.pathname === appPage.url ? "selected" : ""}
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList>
          <IonItem >
            <LogOut />
            <ButtonRouter routerLink="/config" text="Config." icon={settingsOutline} />
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

import { UsuarioProvider } from "context/UserContext";
import Routes from "./routes";
import { ErrorBoundary } from "react-error-boundary";

export default function RoutesProviders() {
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <UsuarioProvider>
        <Routes />
      </UsuarioProvider>
    </ErrorBoundary>
  );
}

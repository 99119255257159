import { IonGrid, IonRow, IonCol, IonItem, IonLabel, IonButton, IonSelect, IonSelectOption, useIonAlert, useIonLoading } from '@ionic/react'
import axios from 'axios';
import { useUserContext } from 'context/UserContext';
import React, { useLayoutEffect, useState } from 'react'
import styles from "./FormPessoa.module.scss";
import estados from "./estadosDoBrasil.json";
import API from 'services';
import { Pessoa } from 'types/Pessoa';
import { useHistory } from 'react-router-dom';
import InputItem from 'components/Input';

interface Props {
  idPessoa?: string;
  tipoPessoa: "F" | "J";
  pessoa?: Pessoa | undefined;
  type: "cadastro" | "atualizacao";
}

export default function FormPessoa(props: Props) {
  const { user } = useUserContext();
  const [nome, setNome] = useState<string | number | null | undefined>("");
  // const [tipo, setTipo] = useState(props.tipoPessoa);
  const [documento, setDocumento] = useState<string | number | null | undefined>("");
  const [cep, setCep] = useState<string | number | null | undefined>("");
  const [logradouro, setLogradouro] = useState<string | number | null | undefined>("");
  const [complemento, setComplemento] = useState<string | number | null | undefined>("");
  const [bairro, setBairro] = useState<string | number | null | undefined>("");
  const [localidade, setLocalidade] = useState<string | number | null | undefined>("");
  const [uf, setUf] = useState<string | number | null | undefined>("");
  const [telefone, setTelefone] = useState<string | number | null | undefined>("");
  const [email, setEmail] = useState<string | number | null | undefined>("");
  const [obsetvacao, setObservacao] = useState<string | number | null | undefined>("");

  const [presentAlert] = useIonAlert();
  const [present] = useIonLoading();
  const history = useHistory();

  useLayoutEffect(() => {
    if (props.pessoa) {
      setNome(props.pessoa.nome)
      setDocumento(props.pessoa.documento)
      setCep(props.pessoa.cep)
      setLogradouro(props.pessoa.logradouro)
      setComplemento(props.pessoa.complemento)
      setBairro(props.pessoa.bairro)
      setLocalidade(props.pessoa.localidade)
      setUf(props.pessoa.uf)
      setTelefone(props.pessoa.telefone)
      setEmail(props.pessoa.email)
      setObservacao(props.pessoa.observacao)
    }
  }, [props.pessoa])

  function enviarFormulario(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    const pessoa = {
      nome: nome,
      responsavel: user?.id,
      tipo: props.tipoPessoa,
      documento: documento,
      cep: cep,
      logradouro: logradouro,
      complemento: complemento,
      bairro: bairro,
      localidade: localidade,
      uf: uf,
      telefone: telefone,
      email: email,
      observacao: obsetvacao,
      orgao: user?.orgao
    };

    let linkApi = "";
    if (props.type === "cadastro") {
      linkApi = "/usuarios/pessoaById/createPessoa.php";
      API.post(`${linkApi}`, pessoa)
        .then(resp => {
          if (resp.data.erro) {
            presentAlert({
              header: `${resp.data.mensagem}!`,
              message: "Documento deve ser único para cada pessoa!",
              buttons: ["OK"],
            });
          } else {
            presentAlert({
              header: "Cadastrado realizado com sucesso!",
              message: "Voltar para pagina principal",
              buttons: ["OK"],
              onDidDismiss: () => {
                history.push(props.tipoPessoa === "F" ? "/" : "/todas_atividades")
              }
            });
          }
        })
        .catch(err => {
          alert(`Algo deu errado...${err}`)
        })
    }

    if (props.type === "atualizacao") {
      linkApi = "AtualizaPessoa";
      API.post(`${linkApi}`, pessoa)
        .then(resp => {
          console.log(resp)
        })
        .catch(err => {
          console.log(err)
        })
    }
  };

  const ViaCep = () => {
    console.log(cep)
    axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((resp) => {
        if (resp.data.erro) {
          presentAlert({
            header: "Algo inesperado aconteceu...",
            message: "Verifique o CEP",
            buttons: ["OK"],
          });
        } else {
          setLogradouro(resp.data.logradouro);
          setComplemento(resp.data.complemento);
          setBairro(resp.data.bairro);
          setLocalidade(resp.data.localidade);
          setUf(resp.data.uf.toUpperCase());
        }
      })
      .catch((erro) => {
        alert(erro.message);
      });
  };

  return (
    <form onSubmit={(ev) => { enviarFormulario(ev) }}>
      <IonGrid>
        <IonRow>
          <IonCol size-xs="12" size-md="12" size-lg="12">
            <InputItem
              required
              state={nome}
              setState={setNome}
              placeholder="Nome"
              position='stacked'
              label='Nome'
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size-xs="12" size-md="12" size-lg="6">
            <InputItem
              required
              type='email'
              state={email}
              setState={setEmail}
              placeholder="E-mail"
              label='E-mail'
              position='stacked'
            />
          </IonCol>

          <IonCol size-xs="12" size-md="12" size-lg="6">
            <InputItem
              required
              state={telefone}
              setState={setTelefone}
              placeholder="Telefone"
              label='Telefone'
              position='stacked'
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size-xs="12" size-md="12" size-lg="6">
            <InputItem
              required
              state={documento}
              setState={setDocumento}
              placeholder="Documento"
              label='Documento'
              position='stacked'
            />
          </IonCol>

          <IonCol size-xs="12" size-md="12" size-lg="6">
            <IonItem>
              <div className={styles.inputCep}>
                <div>
                  <InputItem
                    required
                    type='number'
                    state={cep}
                    setState={setCep}
                    placeholder="CEP"
                    label='CEP'
                    position='stacked'
                  />
                </div>
                <IonButton onClick={ViaCep}>Buscar</IonButton>
              </div>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size-xs="12" size-md="12" size-lg="6">
            <IonItem>
              <IonLabel position="stacked">UF</IonLabel>
              <IonSelect
                onIonChange={(ev) => setUf(ev.target.value)}
                value={uf}
                placeholder="Selecione um Estado"
                interface="popover"
              >
                {estados.map((estado) => (
                  <IonSelectOption key={estado.sigla} value={estado.sigla}>
                    {estado.nome}
                  </IonSelectOption>)
                )}
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol size-xs="12" size-md="12" size-lg="6">
            <InputItem
              required
              state={localidade}
              setState={setLocalidade}
              placeholder="Localidade"
              label='Localidade'
              position='stacked'
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size-xs="12" size-md="12" size-lg="6">
            <InputItem
              required
              state={bairro}
              setState={setBairro}
              placeholder="Bairro"
              label='Bairro'
              position='stacked'
            />
          </IonCol>
          <IonCol size-xs="12" size-md="12" size-lg="6">
            <InputItem
              required
              state={logradouro}
              setState={setLogradouro}
              placeholder="Logradouro"
              label='Logradouro'
              position='stacked'
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size-xs="12" size-md="12" size-lg="12">
            <InputItem
              state={complemento}
              setState={setComplemento}
              placeholder="Complemento"
              label='Complemento'
              position='stacked'
            />
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12">
            <InputItem
              state={obsetvacao}
              setState={setObservacao}
              placeholder="Observacao"
              label='Observacao'
              position='stacked'
            />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonItem className={styles.containerBotoes}>
        <IonButton type="reset" size="default" color="danger">
          Limpar
        </IonButton>
        <IonButton type='submit' size="default" color="success" slot="end">
          {props.type}
        </IonButton>
      </IonItem>
    </form>
  )
}




import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonItem, IonRow, IonText } from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import styles from "./CardProcessos.module.scss";
import ButtonRouter from "components/ButonRouter";

interface IProps {
  id: number;
  numero_processo: string;
  empregador: string;
  empregado: string;
  funcionario: string;
  datacadastro: string;
  observacao: string | null;
  orgao: string;
}

const CardProcessos = ({ id, datacadastro, empregado, empregador, funcionario, observacao, orgao, numero_processo }: IProps) => {
  console.log({ id, datacadastro, empregado, empregador, funcionario, observacao, orgao, numero_processo })
  return (
    <IonCard className={styles.ionCard}>
      <IonCardHeader>
        <IonCardTitle>Processo: {numero_processo}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonText>Empresa: </IonText>
            </IonCol>
            <IonCol>
              <IonText>{empregador}</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>Pessoa: </IonText>
            </IonCol>
            <IonCol>
              <IonText>{empregado}</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>Responsável:</IonText>
            </IonCol>
            <IonCol>
              <IonText>{funcionario}</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>Data de Cadastro: </IonText>
            </IonCol>
            <IonCol>
              <IonText>{new Date().toLocaleDateString('pt-BR')}</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>Órgão: </IonText>
            </IonCol>
            <IonCol>
              <IonText>{orgao}</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>Observação: </IonText>
            </IonCol>
            <IonCol>
              <IonText>{observacao}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonItem>
          <ButtonRouter
            slotButton="end"
            slotIcon="end"
            routerLink={`processo/:${id}`}
            icon={pencilOutline}
            text="editar"
            size="small"
            fill="default"
            colorButton="dark"
            colorIcon="dark"
          />
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default CardProcessos;

import { createContext, useState, useContext, useEffect } from "react";
import { Usuario } from "types/Usuario";

interface CurrentUserContextType {
  loginValido: boolean;
  setLoginValido: React.Dispatch<React.SetStateAction<boolean>>;

  user: Usuario | undefined | null;
  setUser: React.Dispatch<React.SetStateAction<Usuario | undefined | null>>;
}

interface User {
  ativo: string;
  email: string;
  id: number;
  nome: string;
  orgao: number;
  perfil: string;
}

const DEFAULT_CONTEXT = {
  loginValido: false,
  setLoginValido: () => { },

  user: null,
  setUser: () => { },
};

export const UserContext = createContext<CurrentUserContextType>(DEFAULT_CONTEXT);
UserContext.displayName = "Usuario";

type Props = {
  children: string | JSX.Element | JSX.Element[];
};

export const UsuarioProvider = ({ children }: Props) => {
  const [loginValido, setLoginValido] = useState<boolean>(false);
  const [user, setUser] = useState<Usuario | null>();

  useEffect(() => {
    const userLocalStorage = localStorage.getItem("user");

    if (userLocalStorage) {
      const usuarioLocalStorage = JSON.parse(userLocalStorage);
      setUser(usuarioLocalStorage);
      setLoginValido(true);
    } else {
      setUser(null);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        loginValido,
        setLoginValido,
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const userContext = useContext(UserContext);
  const { loginValido, setLoginValido, setUser, user } = userContext;

  const handleLogin = (usuario: User) => {
    setLoginValido(true);
    setUser(usuario);
    localStorage.setItem("user", JSON.stringify(usuario));
  }

  return {
    loginValido,
    handleLogin,
    user
  };
};

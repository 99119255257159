import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonLabel,
  IonRadioGroup,
  IonRadio,
  IonText,
} from '@ionic/react';
import API from 'services';

interface IAtividade {
  id_empresa: number,
  atividade: string,
  empresa: string
}

interface Props {
  title?: string;
  onClose?: () => void;
  setAtividade: React.Dispatch<React.SetStateAction<IAtividade | undefined>>
  atividade: IAtividade | undefined;
}

function ModalAtividade({ setAtividade, atividade, title, onClose }: Props) {
  const [filtroAtividade, setFiltroAtividade] = useState("");
  const [listaDeAtividade, setListaDeAtividade] = useState<IAtividade[]>([]);

  useEffect(() => {
    if (filtroAtividade.length >= 3) {
      API.post("/atividades/ativOfertadasapi.php", { atividade: filtroAtividade })
        .then((resp) => {
          setListaDeAtividade(resp.data.atividade.slice(0, 50))
        })
        .catch((err) => {
          alert(err)
        })
    }
    if (filtroAtividade.length === 0) {
      setListaDeAtividade(listaDeAtividade.slice(0, 0))
    }
  }, [filtroAtividade])

  const searchbarInput = (ev: any) => {
    setFiltroAtividade(ev.target.value);
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onClose} color="danger" fill='solid'>Fechar</IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose} color="success" fill='solid'>Concluir</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar onIonInput={searchbarInput}></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
        <IonRadioGroup value={atividade} onIonChange={(ev) => { console.log(ev.detail.value); setAtividade(ev.target.value) }}>
          {listaDeAtividade.map((item, index) => (
            <IonItem key={index}>
              <IonLabel >{item.atividade}</IonLabel>
              <IonLabel >{item.empresa}</IonLabel>
              <IonRadio value={item} />
            </IonItem>
          ))}
        </IonRadioGroup>
      </IonContent>
    </>
  );
}
export default ModalAtividade;
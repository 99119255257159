import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent, IonPage } from '@ionic/react'
import Cabecalho from 'components/Cabecalho'
import FormPessoa from 'components/FormPessoa'

export default function CadastrarEmpresa() {

  return (
    <IonPage>
      <Cabecalho texto='Cadastrar Nova Empresa' />

      <IonContent>
        <IonCard>
          <IonCardHeader>
          <IonCardTitle>Informações da Empresa</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <FormPessoa 
              tipoPessoa='J' 
              type='cadastro' 
            />
          </IonCardContent>

        </IonCard>
      </IonContent>
    </IonPage>
  )
}

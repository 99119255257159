import styles from "./Processos.module.scss";
import { IonButton, IonCol, IonContent, IonGrid, IonItem, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cabecalho from "components/Cabecalho";
import BarraPesquisa from "components/BarraPesquisa";
import CardProcessos from "./CardProcessos";
import API from "services";
import { useUserContext } from "context/UserContext";


interface IProcesso {
  id: number;
  numero_processo: string;
  empregador: string;
  empregado: string;
  funcionario: string;
  datacadastro: string;
  observacao: string | null;
  orgao: string;
}

const Processos: React.FC = () => {
  const [busca, setBusca] = useState("");
  const [lista, setLista] = useState<IProcesso[]>([]);
  const { user } = useUserContext();

  useEffect(() => {
    if (busca.length >= 3 || busca.length === 0) {
      API.post("/processo/filtro_procbyuserID.php", { id: user?.id, numero: busca })
        .then(resp => {
          console.log(resp.data)
          setLista(resp.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [busca, setBusca])

  return (
    <IonContent>
      <Cabecalho texto="Processos">
        <IonGrid>
          <IonRow >
            <IonCol sizeSm='12' sizeMd='12' sizeLg='6' sizeXl='6'>
              <BarraPesquisa placeholder="Nº do Processo" busca={busca} setBusca={setBusca} />
            </IonCol>
            <IonCol>
              <Link to="/processo/cadastro" style={{textDecoration: "none"}}>
                <IonItem lines="none">
                  <IonButton slot="end" color="success" size="default">
                    Adiconar Processo
                  </IonButton>
                </IonItem>
              </Link>
            </IonCol>
          </IonRow>
        </IonGrid>
      </Cabecalho>

      <div className={styles.cardsContainer}>
        {lista?.length
          ? (
            lista.map((processo, index) => (
              <CardProcessos key={index} {...processo} />
            ))
          )
          : (
            <div className={styles.containerListaVazia}>
              <p>Nem um precesso encontrado...</p>
            </div>
          )
        }
      </div>
    </IonContent>
  );
};

export default Processos;

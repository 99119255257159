import { IonContent, IonPage, IonItem, IonSelect, IonSelectOption, IonText, IonCard, IonCardHeader, IonCardTitle } from "@ionic/react";
import { useEffect } from "react";
import { useState } from "react";
import API from "services";
import { useUserContext } from "context/UserContext";
import Cabecalho from "components/Cabecalho";
import { useLocation } from "react-router";

interface Empresa {
  id: number;
  nome: string;
}

interface Atividade {
  atividades: string;
}

export default function TodasAtividades() {
  const location = useLocation();
  const [empresas, setEmpresas] = useState<Empresa[]>([]);
  const [listaDeAtividades, setListaDeAtividades] = useState<Atividade[]>([]);
  const [mensagem, setMensagem] = useState("");
  const { user } = useUserContext();

  useEffect(() => {
    API.post("/usuarios/funcionarios/funcionarios_empresasapi.php", { id: user?.id })
      .then((resp) => {
        setEmpresas(resp.data)
      })
      .catch((err) => {
        alert(err)
      })

    console.log(user?.email)
  }, [])

  const handleSelection = (id: number) => {
    API.post("/usuarios/empresas/ativEmpresByID.php", { id: id })
      .then((resp) => {
        if (resp.data.error) {
          console.log(resp)
          setMensagem(resp.data.message);
        } else {
          setListaDeAtividades(resp.data)
          setMensagem("");
        }
      })
      .catch((err) => {
        alert(err)
        console.log(err)
      })
  }

  return (
    <IonPage>
      <Cabecalho texto="Todas as Atividades">
        <IonItem>
          <IonSelect onIonChange={(ev) => { handleSelection(ev.target.value) }} placeholder="Selecione uma empresa" interface="popover">
            {empresas?.map((empresa) => {
              return (
                <IonSelectOption key={empresa.id} value={empresa.id}>
                  <IonText>{`${empresa.nome}`}</IonText>
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </IonItem>
      </Cabecalho>
      <IonContent>
        {mensagem ?
          (
            <IonCard>
              <IonCardHeader>
                <IonCardTitle style={{ display: 'grid', placeItems: 'center' }}>{mensagem}</IonCardTitle>
              </IonCardHeader>
            </IonCard>
          ) :
          (
            listaDeAtividades?.map((ativ) => {
              return (
                <IonCard key={ativ.atividades}>
                  <IonCardHeader>
                    <IonCardTitle>{ativ.atividades.toUpperCase()}</IonCardTitle>
                  </IonCardHeader>
                </IonCard>
              )
            })
          )
        }
      </IonContent>
    </IonPage>
  );
}

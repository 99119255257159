import { IonItem, IonLabel, IonSelect, IonSelectOption, IonText } from '@ionic/react';
import React from 'react'
interface Props {
    value: string,
    setValue: React.SetStateAction<any>,
    options: string[],
    label: string
}


export default function ItemPersonalizavel({ value, setValue, options, label }: Props) {
    return (
        <IonItem>
            <IonLabel>{label}</IonLabel>
            <IonSelect value={value} onIonChange={(ev) => { setValue(ev.detail.value); }} interface="popover">
                {options.map((op, index) => {
                    return (
                        <IonSelectOption key={index} value={op}>
                            <IonText>{`${op}`}</IonText>
                        </IonSelectOption>
                    );
                })}
            </IonSelect>
        </IonItem>
    )
}

import { IonPage, IonContent, IonCardContent, IonItem, IonButton, IonCard, IonCardHeader, IonCardTitle, IonModal, IonInput, IonLabel, useIonAlert } from "@ionic/react";
import React, { useRef, useState } from "react";
import styles from "./CadastrarAtividades.module.scss";
import { useHistory } from "react-router";
import API from "services";
import Cabecalho from "components/Cabecalho";
import ModalTipoAtividade from "./ModalTipoAtividade";
import { TipoAtividade } from "types/TipoAtividade";
import { useUserContext } from "context/UserContext";
import ModalEmpresa from "./ModalEmpresa";

interface Empresa {
  id: number;
  nome: string;
}

export default function CadastrarAtividade() {
  const { user } = useUserContext();
  const [atividade, setAtividade] = useState<TipoAtividade>();
  const [empresa, setEmpresa] = useState<Empresa>();
  const [presentAlert] = useIonAlert();
  const history = useHistory();
  const modalTipoAtividade = useRef<HTMLIonModalElement>(null);
  const modalEmpresa = useRef<HTMLIonModalElement>(null);

  const submitForm = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const nova_atividade = {
      empresa: empresa?.id,
      atividade: atividade?.id,
      orgao: user?.orgao,
    };

    API
      .post("/atividades/create_Ativ.php", nova_atividade)
      .then((resp) => {
        console.log(resp)
        if (resp.data.error) {
          presentAlert({
            header: "Erro ao cadastrar...",
            message: `${resp.data.message}`,
            buttons: ["OK"],
          });
        } else {
          presentAlert({
            header: "Cadastrado realizado com sucesso!",
            message: "Voltar para pagina principal",
            buttons: ["OK"],
            onDidDismiss: () => {
              setAtividade(undefined)
              setEmpresa(undefined)
              history.push("/todas_atividades")
            }
          });
        }
      })
      .catch((error) => {
        alert(error)
      });
  };

  return (
    <IonPage>
      <Cabecalho texto="Cadastrar Atividade" />

      <IonContent className={styles.ionContent}>
        <div className={styles.ionCardContainer}>
          <IonCard>
            <IonCardHeader>
              <div className={styles.IonCardTitleContainer}>
                <IonCardTitle>Cadastrar nova atividade</IonCardTitle>
              </div>
            </IonCardHeader>
            <IonCardContent>
              <form onSubmit={(ev) => submitForm(ev)}>

                <IonItem id="empresa">
                  <IonLabel position="stacked">Empresa</IonLabel>
                  <IonInput required value={empresa?.nome} placeholder="Selecione uma Empresa" />
                </IonItem>

                <IonModal trigger="empresa" ref={modalEmpresa}>
                  <ModalEmpresa
                    title="Selecione uma Empresa"
                    setState={setEmpresa}
                    state={empresa}
                    onClose={() => { modalEmpresa.current?.dismiss(); console.log(empresa) }}
                  />
                </IonModal>

                <IonItem id="atividade">
                  <IonLabel position="stacked">Atividade</IonLabel>
                  <IonInput required value={atividade?.descricao} placeholder="Selecione uma Atividade" />
                </IonItem>

                <IonModal trigger="atividade" ref={modalTipoAtividade}>
                  <ModalTipoAtividade
                    title="Selecione uma Atividade"
                    setState={setAtividade}
                    state={atividade}
                    onClose={() => { modalTipoAtividade.current?.dismiss(); console.log(atividade?.descricao) }}
                  />
                </IonModal>

                <IonItem>
                  <IonButton type="reset" size="default" color="danger" fill="solid">
                    Cancelar
                  </IonButton>
                  <IonButton type="submit" size="default" color="success" fill="solid" slot="end">
                    Salvar
                  </IonButton>
                </IonItem>
              </form>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
}



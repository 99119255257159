import { IonButton, IonCard, IonCardContent, IonContent, IonIcon, IonItem, IonLabel } from '@ionic/react'
import ButtonRouter from 'components/ButonRouter';
import Cabecalho from 'components/Cabecalho'
import { lockClosedOutline, personOutline, settingsOutline } from "ionicons/icons";
import { Link } from 'react-router-dom';

export default function Configuracoes() {

  return (
    <IonContent>
      <Cabecalho texto='Configurações' />
      <IonCard>
        <IonCardContent>
          <IonItem lines="none">
            <IonLabel>Senhas</IonLabel>
            <ButtonRouter routerLink="/config/senha" text="Alterar Senha" icon={lockClosedOutline} />
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Avatar</IonLabel>
            <ButtonRouter routerLink="/config/avatar" text="Personalizar Avatar" icon={personOutline} />
          </IonItem>
        </IonCardContent>
      </IonCard>
    </IonContent>
  )
}

import axios from "axios";

const API = axios.create({
    baseURL: "https://www.alimentosefamilia.com.br/api",
    headers: {
        Accept: "application/json",
        Content: "application/json",
        "Content-Type": "multipart/form-data",
    },
});
// const API = axios.create({
//     baseURL: "https://www.alimentosefamilia.com.br/pages",
//     headers: {
//         Accept: "application/json",
//         Content: "application/json",
//         "Content-Type": "multipart/form-data",
//     },
// });

export default API;

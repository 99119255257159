import { IonContent, IonHeader, IonToolbar, IonTitle, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonInput, IonItem, IonLabel, IonTextarea, IonIcon, useIonLoading, useIonAlert, IonButtons, IonMenuButton, IonModal } from "@ionic/react";
import { useRef, useState } from "react";
import { saveOutline, trashOutline } from "ionicons/icons";

import styles from "./PaginaCadastroProcesso.module.scss";
import Alerta from "./Alert";
import API from "services";
import ModalAtividade from "./ModalAtividade";
import ModalPessoa from './ModalPessoa/index';
import { useUserContext } from "context/UserContext";
import { useHistory } from "react-router";

interface IPessoa {
  id: number;
  nome: string;
  cpf: string;
}

interface IAtividade {
  id_empresa: number,
  atividade: string,
  empresa: string
}

export default function CadastroProcesso() {
  const [numeroProcesso, setNumeroProcesso] = useState("");
  const [pessoa, setPessoa] = useState<IPessoa | undefined>();
  const [atividade, setAtividade] = useState<IAtividade | undefined>();
  const [observacao, setObaservacao] = useState("");
  const { user } = useUserContext();
  const history = useHistory();

  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState("");

  const [present] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const modalAtividade = useRef<HTMLIonModalElement>(null);
  const modalPessoa = useRef<HTMLIonModalElement>(null);

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (numeroProcesso === "" || pessoa === undefined || atividade === undefined) {
      presentAlert({
        header: "Atenção",
        subHeader: "Preencha todos os capos.",
        message: "O campo Observação não é obrigatorio.",
        buttons: ["OK"],
      });
    } else {

      const processo = {
        processo: numeroProcesso,
        empregador: atividade.id_empresa,
        empregado: pessoa?.id,
        funcionario: user?.id,
        observacao: observacao,
        orgao: user?.orgao
      };

      console.log(processo)
      API.post("/processo/cadastro_processoapi.php", processo)
        .then((resp) => {
          console.log(resp)
          if (resp.data.error) {
            alert(`${resp.data.message}`)
          } else {
            present({
              message: "Cadastrando Processo",
              duration: 1000,
              spinner: "dots",
            });

            setTimeout(() => {
              setNumeroProcesso("");
              setPessoa(undefined)
              setAtividade(undefined)
              setObaservacao("");
              history.push("/")
            }, 1100)
          }
        })
        .catch((error) => {
          console.log(error)
          alert(`${error.mensagem}`)
        });
    }
  };

  return (
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton></IonMenuButton>
          </IonButtons>
          <IonTitle>Cadastrar Processo</IonTitle>
        </IonToolbar>
      </IonHeader>

      <Alerta isOpen={alertIsOpen} setIsOpen={setAlertIsOpen} header={alertHeader} />

      <div className={styles.container}>
        <IonCard className={styles.ionCard}>
          <IonCardHeader>
            <IonItem>
              <IonCardTitle>Novo Processo</IonCardTitle>
            </IonItem>
          </IonCardHeader>

          <IonCardContent>
            <form onSubmit={(ev) => handleSubmit(ev)}>

              <IonItem>
                <IonLabel position="stacked"> Numero do Processo </IonLabel>
                <IonInput
                  value={numeroProcesso}
                  onIonChange={(ev) => {
                    setNumeroProcesso(`${ev.target.value}`);
                  }}
                  placeholder="Numero do Processo"
                ></IonInput>
              </IonItem>

              <IonItem id="pessoa">
                <IonLabel position="stacked">Pessoa</IonLabel>
                <IonInput value={pessoa?.nome} placeholder="Selecione uma Pessoa" />
              </IonItem>

              <IonModal trigger="pessoa" ref={modalPessoa}>
                <ModalPessoa
                  title="Selecione uma Pessoa"
                  setState={setPessoa}
                  state={pessoa}
                  onClose={() => { modalPessoa.current?.dismiss() }}
                />
              </IonModal>

              <IonItem id="atividade">
                <IonLabel position="stacked">Atividade</IonLabel>
                <IonInput value={atividade?.atividade} placeholder="Selecione uma Atividade" />
              </IonItem>

              <IonModal trigger="atividade" ref={modalAtividade}>
                <ModalAtividade
                  title="Selecione uma Atividade"
                  setAtividade={setAtividade}
                  atividade={atividade}
                  onClose={() => { modalAtividade.current?.dismiss() }}
                />
              </IonModal>

              <IonItem>
                <IonLabel position="stacked">Observação</IonLabel>
                <IonTextarea
                  value={observacao}
                  onIonChange={(ev) => {
                    setObaservacao(`${ev.target.value}`);
                  }}
                  placeholder="Observação"
                  autoGrow={true}

                ></IonTextarea>
              </IonItem>

              <div className={styles.butons}>
                <IonButton color="danger" type="reset">
                  <IonIcon src={trashOutline} slot="start" />
                  Limpar
                </IonButton>
                <IonButton type="submit" color="success" onClick={() => handleSubmit}>
                  <IonIcon src={saveOutline} slot="start" />
                  Salvar
                </IonButton>
              </div>
            </form>
          </IonCardContent>
        </IonCard>
      </div>
    </IonContent>
  );
}

import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonContent } from "@ionic/react";
import styles from "./CadastrarPessoa.module.scss";
import Cabecalho from "components/Cabecalho";
import FormPessoa from "components/FormPessoa";

export default function CadastrarPessoa() {
  return (
    <IonContent>
      <Cabecalho texto='Cadastrar Pessoa' />

      <section className={styles.container}>
        <IonCard className={styles.card}>
          <IonCardHeader>
            <IonCardTitle>Dados Pessoais</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            <FormPessoa
              type="cadastro"
              tipoPessoa="F"
            />
          </IonCardContent>
        </IonCard>
      </section>
    </IonContent >
  );
}

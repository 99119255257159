import { IonContent, IonPage } from '@ionic/react'
import Cabecalho from 'components/Cabecalho'

export default function Home() {
  return (
    <IonContent>
      <Cabecalho texto='Bem Vindo' />
      <IonContent>
      </IonContent>
    </IonContent>
  )
}

import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonLabel,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';
import API from 'services';
import { TipoAtividade } from 'types/TipoAtividade';

interface Props {
  title?: string;
  onClose?: () => void;
  setState: React.Dispatch<React.SetStateAction<TipoAtividade | undefined>>
  state: TipoAtividade | undefined;
}

function ModalTipoAtividade({ setState, state, title, onClose }: Props) {
  const [listaDeTipoAtividades, setListaDeTipoAtividades] = useState<TipoAtividade[]>([]);
  const [listaFiltrada, setListaFiltrada] = useState<TipoAtividade[]>([]);

  useEffect(() => {
    API.get("/atividades/getAllAtiv.php")
        .then((resp) => {
          setListaFiltrada(resp.data.slice(0, 50))
          setListaDeTipoAtividades(resp.data)
        })
        .catch((err) => { 
          alert(err)
        })
  },[])

  const searchbarInput = (ev: any) => {
    const string = ev.target.value
    const b = string && string.toLowerCase();

    const result = listaDeTipoAtividades.filter((atividade) => atividade.descricao.toLowerCase().includes(b));

    console.log(result);
    if (result) {
      if (result.length >= 50) {
        setListaFiltrada(result.slice(0, 100));
      } else {
        setListaFiltrada(result);
      }
    }
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onClose} color="danger" fill='solid'>Fechar</IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onClose} color="success" fill='solid'>Concluir</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar onIonInput={searchbarInput} ></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
        <IonRadioGroup value={state} onIonChange={(ev) => { setState(ev.detail.value) }}>
          {listaFiltrada.map((atividade) => (
            <IonItem key={atividade.id}>
              <IonLabel>{atividade.descricao}</IonLabel>
              <IonRadio value={atividade} />
            </IonItem>
          ))}
        </IonRadioGroup>
      </IonContent>
    </>
  );
}
export default ModalTipoAtividade;
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonImg, IonItem, IonLabel, IonPage, IonSelect, IonSelectOption, useIonAlert } from "@ionic/react";
import brasaoES from "assets/brasaoES.png";
import styles from "./PaginaCadastroUsusario.module.scss";
import { useEffect, useState } from "react";
import API from "services";
import { Orgao } from "types/Orgao";
import InputItem from "components/Input";
import { useHistory } from "react-router";

export default function CadastroUsuario() {
  const [nome, setNome] = useState<string | number | null | undefined>("");
  const [email, setEmail] = useState<string | number | null | undefined>("");
  const [senha, setSenha] = useState<string | number | null | undefined>("");
  const [confirmaSenha, setConfirmaSenha] = useState<string | number | null | undefined>("");
  const [perfil, setPerfil] = useState<string | number | null | undefined>("");
  const [orgao, setOrgao] = useState<string | number | null | undefined>("abc");
  const [listaOrgao, setListaOrgao] = useState<Orgao[]>();
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  useEffect(() => {
    API.get("/orgao/orgaoapi.php")
      .then((res) => {
        setListaOrgao(res.data);
      })
      .catch((err) => {
        alert(err)
      });
  }, []);

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (senha !== confirmaSenha) {
      console.log("senhas Diferentes");

      presentAlert({
        header: "Atenção",
        subHeader: "Senhas diferentes.",
        message: "As senhas devem ser iguais.",
        buttons: ["OK"],
      });

      return;
    } else {
      const novoUsuario = {
        nome: nome,
        email: email,
        senha: senha,
        perfil: perfil,
        orgao: orgao,
      };
      console.log(novoUsuario)

      API.post("/cadastro/cadastroNovoUsuapi.php", novoUsuario)
        .then((resp) => {
          if (resp.data.error === true) {
            presentAlert({
              header: "Atenção",
              message: `${resp.data.message}`,
              buttons: ["OK"],
            });
          } else {
            presentAlert({
              header: `${resp.data.message}`,
              message: "Voltar para pagina de Login",
              buttons: ["OK"],
              onDidDismiss: () => {
                history.push("/login")
              }
            });
          }
        })
        .catch((err) => {
          alert(err)
        });
    }
  };

  return (
    <IonPage className={styles.page}>
      <IonCard className={styles.ionCard}>
        <IonCardHeader>
          <IonCardTitle>Alimentos e Familia</IonCardTitle>
          <IonImg className={styles.imagem} src={brasaoES} alt="logo" />
        </IonCardHeader>

        <IonCardContent>
          <form onSubmit={(ev) => handleSubmit(ev)}>

            <InputItem
              required
              state={nome}
              setState={setNome}
              position="stacked"
              placeholder="Nome Completo"
              label="Nome"
            />
            <InputItem
              required
              state={email}
              setState={setEmail}
              position="stacked"
              placeholder="E-mail"
              label="E-mail"
            />
            <InputItem
              required
              state={senha}
              setState={setSenha}
              minlength={6}
              position="stacked"
              placeholder="Senha"
              label="Senha"
              type="password"
            />
            <InputItem
              required
              state={confirmaSenha}
              setState={setConfirmaSenha}
              minlength={6}
              position="stacked"
              placeholder="Confirmar Senha"
              label="Confirmar Senha"
              type="password"
            />

            <IonItem>
              <IonLabel position="floating">Perfil</IonLabel>
              <IonSelect interface="popover" onIonChange={(e) => setPerfil(e.detail.value)}>
                <IonSelectOption value="1">Membro</IonSelectOption>
                <IonSelectOption value="2">Pessoa Jurídica</IonSelectOption>
                <IonSelectOption value="3">Pessoa Física</IonSelectOption>
              </IonSelect>
            </IonItem>

            <IonItem>
              <IonLabel position="floating">Órgão</IonLabel>
              <IonSelect interface="popover" onIonChange={(e) => setOrgao(e.detail.value)}>
                {listaOrgao?.map((org) => {
                  return (
                    <IonSelectOption key={org.codigoOrgao} value={org.codigoOrgao}>
                      {org.descricaoOrgao}
                    </IonSelectOption>
                  );
                })}
              </IonSelect>
            </IonItem>

            <IonItem>

              <IonButton type="reset" size="default" fill="solid" color="danger">
                Limpar
              </IonButton>
              <IonButton type="submit" slot="end" size="default" fill="solid" color="success">
                Confirmar
              </IonButton>
            </IonItem>
          </form>
        </IonCardContent>
      </IonCard>
    </IonPage>
  );
}
